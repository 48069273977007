<template>
  <v-row>
    <SidePage />
    <v-col cols="12" md="4" lg="4" xl="4" class="d-flex align-center">
      <v-container>
        <div class="pa-5 pa-sm-10">
          <v-row justify="center">
            <v-col cols="12" md="12" lg="12" xl="12">
              <img src="@/assets/images/logos/logo-comillas.png" width="40" />

              <v-row 
                class="mx-1 mt-0 mb-5 d-flex align-end justify-space-between"
              >              
                <h2 class="font-weight-bold blue-grey--text text--darken-2">Sign up</h2>
                
                <small class="text-decoration-underline">
                  Sign up as
                  <router-link :to="{ name: 'Signup-admin' }">
                    Admin
                  </router-link>
                </small>
              </v-row>
              <v-form 
                ref="form" 
                v-model="valid" 
                lazy-validation 
              >
                <v-text-field
                  v-model="form.name"
                  :rules="editedItemRules.nameRules"
                  label="Name"
                  class="mt-2"
                  required
                  outlined
                ></v-text-field>

                <v-text-field
                  v-model="form.last_name"
                  :rules="editedItemRules.lastNameRules"
                  label="Last Name"
                  required
                  outlined
                ></v-text-field>

                <v-text-field 
                  v-model="form.email" 
                  :rules="editedItemRules.emailRules" 
                  label="E-mail" 
                  required 
                  outlined
                ></v-text-field>

                <v-text-field
                  v-model="form.company_code"
                  :rules="editedItemRules.companyCodeRules"
                  label="Company Code"
                  required
                  outlined
                ></v-text-field>

                <v-text-field
                  v-model.trim="form.password"                  
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show1 ? 'text' : 'password'"
                  @click:append="show1 = !show1"
                  label="Password"
                  name="Password"
                  counter
                  :rules="editedItemRules.passwordRules"
                  outlined
                  required
                ></v-text-field>

                <v-text-field
                  v-model="form.confirmPassword"
                  @keydown.enter="btnClick"
                  :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show2 ? 'text' : 'password'"
                  @click:append="show2 = !show2"
                  label="Confirm password"
                  name="Password"
                  :rules="editedItemRules.confirmPasswordRules"
                  counter
                  outlined
                  required
                ></v-text-field>

                <v-row class="container mt-0 mb-1 py-0">
                  
                  <v-checkbox
                    v-model="form.acceptTerms"
                    :rules="editedItemRules.acceptTerms"
                    class="pt-0 mt-0"
                    color="secondary"
                    :value="1"
                    :false-value="0"
                    required                    
                  ></v-checkbox>

                  <p
                    class="terms-condition"
                    @click="dialogTermsConditions = true"
                  >
                    Terms and conditions                
                  </p>
                </v-row>

                <v-btn
                  :disabled="!valid"
                  :loading="loading"
                  color="primary"
                  block                  
                  submit
                  @click="btnClick"
                >
                  Sign up
                </v-btn>
              </v-form>

              <div class="d-block align-center mb-4 mt-3 mb-sm-0">                  
                <div class="ml-auto d-flex justify-space-between">
                  <div>
                    <small>Already have an account?</small>
                    <router-link :to="{ name: 'SignIn' }" class="ml-1">Sign in</router-link>
                  </div>
                   
                  <router-link 
                    :to="{ name: 'ForgotPassword' }" 
                  >
                    Forgot password?
                  </router-link>
                </div>
              </div>

              <v-dialog 
                persistent
                v-model="dialogTermsConditions" 
                max-width="750px"
              >
                <TermsAndConditions @closeModal="dialogTermsConditions = false"/>
              </v-dialog>
              
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import TermsAndConditions from '../../components/TermsAndConditions.vue';
import SidePage from './SidePage.vue'
import axios from 'axios';

export default {
  name: "SignUp",
  components:{
    SidePage,
    TermsAndConditions
  },
  data: () => ({
    show1: false,
    show2: false,
    valid: true,
    loader: null,
    loading: false,    
    form: {
      name: '',
      last_name: '',
      email: '',
      company_code: '',
      password: '',
      confirmPassword: '',
      acceptTerms: 0
    },   
    dialogTermsConditions: false              
  }),
  computed: {
    roleName(){
      if(this.form.role == 'Admin') return 'Admin'
      return 'Employee'
    },
    editedItemRules(){
      return {
        nameRules: [
          v => !!v || "Name is required",
          v => (v && v.length > 2) || "Name must be more than 2 characters long",
          v => (v && v.length <= 26) || "Name must be less than 27 characters long"
        ],        
        lastNameRules: [
          v => !!v || "Last name is required",
          v => (v && v.length > 2) || "Last name must be more than 2 characters long",
          v => (v && v.length <= 26) || "Last name must be less than 27 characters long"
        ],
        emailRules: [
          v => !!v || "E-mail is required",
          v => /.+@.+\..+/.test(v) || "E-mail must be valid"
        ], 
        companyCodeRules: [
          v => !!v || "Company code is is required",
          v => (v && v.length == 16) || "Company code must be 16 characters long",
        ],
        passwordRules: [
          v => !!v || "Password is required",
          v => (v && v.length > 4) || "Password must be more than 4 characters long",
          v => (v && v.length <= 26) || "Password must be less than 27 characters long"
        ],
        confirmPasswordRules: [
          (v) => !!v || "Confirm password is required",
          (v) => v === this.form.password || "The password confirmation does not match",
        ],
        acceptTerms: [
          (v) => v === 1 || "You need to accept Terms and conditions",
        ]
      }
    }
  },
  methods: {
    validate () {
      let result = this.$refs.form.validate()
      return result;
    },
    async submit() {
      try {
        const {data} = await axios.post('api/users/signup-employee', this.form);
        this.$alertify.success(data)

        this.loader = null
        this.reset();
        this.resetValidation()
      } catch ({message}) {
        this.$alertify.error(message)
        this.valid = true;
        this.loader = null
      }
    },
    btnClick(){
      this.loader = 'loading'
      const l = this.loader
      this[l] = !this[l]
      this.valid = false;

      setTimeout(() => { 
        if(this.validate()){                
          this.submit()              
        }           
        (this[l] = false)          
      }, 2000)
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    },
    reset () {
      this.$refs.form.reset()
    },
  }
};
</script>
