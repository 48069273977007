<template>
    <v-card>
        <v-card-title class="justify-center" primary-title>
            Términos y Política de Uso de ChatEE
        </v-card-title>
        <v-card-text>
            <p>¡Bienvenido a ChatEE!</p>

            <p>
                Gracias por elegir ChatEE, proporcionado por JSR Technology, LLC.  Antes de comenzar a utilizar nuestro servicio, es necesario que usted revise y acepte los siguientes términos y política de uso.
            </p>

            <p>
                1. Aceptación de los Términos.
                Al acceder o utilizar ChatEE, usted acepta cumplir y estar obligado por estos términos y condiciones, sin excepción. Si usted no está de acuerdo con estos términos y condiciones, usted no debe utilizar ChatEE.
            </p>

            <p>
                2. Licencia Limitada y No Exclusiva de Uso.
                ChatEE le otorga a su Patrono una licencia limitada, no exclusiva y no transferible para usted acceder y utilizar ChatEE según se describe en el Acuerdo de Licencia Limitada y No Exclusiva (el “Acuerdo”) entre ChatEE y su Patrono.  Todos los términos en letras mayúsculas no definidos en este escrito tendrán el significado provisto en el Acuerdo.  Bajo el Acuerdo, usted es un Empleado Participante que puede obtener Resultados con respuestas a preguntas o Búsquedas a través de un Buscador que le permite acceder un Software con información sobre su empleo, empresa, lugar de trabajo, normas de su Patrono y cualquier otra información escogida por su Patrono.  Los Resultados de las Búsquedas están limitados por la información que su Patrono coloque en una Base de Datos, a la entera discreción de su Patrono.  Durante la vigencia del servicio provisto por ChatEE, usted podrá hacer hasta un máximo de 20 Búsquedas mensuales en ChatEE.

            </p>

            <p>
                3. Responsabilidades del Usuario.
                Usted, como Empleado Participante, es responsable de mantener la confidencialidad de sus credenciales de inicio de sesión y es completamente responsable de todas las actividades que ocurran bajo su cuenta.  Usted se compromete a notificar inmediatamente a ChatEE sobre cualquier uso no autorizado de su cuenta o cualquier otra violación de seguridad que usted identifique que permita el acceso de personas no autorizadas a ChatEE.  Usted puede comunicarse con ChatEE a través del correo electrónico. 
            </p>

            <p>
                4. Limitación de Responsabilidad de ChatEE. 
                Usted reconoce y acepta que al utilizar los servicios y productos de ChatEE, ChatEE provee un Software que facilita el Buscador y que el Buscador a su vez actúa a base de la información proporcionada por su Patrono y disponible en el momento de la Búsqueda en la Base de Datos.  Si ChatEE responde incorrectamente a una pregunta suya, usted estipula y acuerda no presentar reclamación o demanda alguna en contra de ChatEE por dicho Resultado o respuesta incorrecta.  Un Resultado puede ser incorrecto por un problema con el funcionamiento adecuado del Software o porque la información en la Base de Datos es incorrecta.  Usted expresamente exonera, releva, defenderá y mantendrá indemne a ChatEE de cualquier responsabilidad, pérdida, daño, coste, gasto, acción legal, demanda, reclamación o cualquier otra consecuencia derivada de, o relacionada con, las respuestas o Resultados proporcionadas por el Software, incluso si esta respuesta es correcta, pero conduce a decisiones o acciones equivocadas por parte suya. 
            </p>

            <p>
                5. Limitación de Responsabilidad por Servicios de Terceros. 
                Usted reconoce y acepta que ChatEE utilizará un servicio de almacenamiento de datos en línea provisto por terceros e incorporará en su Software programación obtenida mediante licencia de ChatGPT, entre otros proveedores de servicios relacionados.  Usted reconoce y acepta que ChatEE no es dueño de ni controla los servicios de esos proveedores externos ni de otros proveedores de servicios.  Por lo tanto, usted expresamente exonera, releva y mantendrá indemne a ChatEE de cualquier responsabilidad, pérdida, daño, coste, gasto, acción legal, demanda, reclamación o cualquier otra consecuencia derivada de, o relacionada con, alguna interrupción en los servicios de esos proveedores, así como por cualquier funcionamiento no intencionado de los servicios y productos de esos terceros proveedores.
            </p>

            <p>
                6. Usos Prohibidos.
                Usted no puede utilizar ChatEE para ningún propósito que sea ilegal o esté prohibido por estos términos.  No puede utilizar ChatEE de ninguna manera que pueda dañar, deshabilitar, sobrecargar o perjudicar el Software, ni interferir con el uso y disfrute del Software por parte de otros usuarios.  Usted tampoco puede usar ChatEE para perjudicar a su Patrono.
            </p>

            <p>
                7. Propiedad Intelectual.
                Todo el contenido, marcas registradas, logotipos y otra propiedad intelectual asociada con ChatEE son propiedad de ChatEE o su Patrono, según dispuesto en el Acuerdo.  Usted no puede copiar, modificar, distribuir, vender ni arrendar ninguna parte de nuestro Software ni del software incluido en este, ni puede realizar ingeniería inversa ni intentar extraer el código fuente de ese software bajo ninguna circunstancia.  Tampoco puede publicar con fines comerciales u otros fines alguno de los Resultados provistos por ChatEE a sus búsquedas.  Usted tampoco podrá desarrollar servicios o software que compita con cualquiera de las funcionalidades o servicios de ChatEE. 
            </p>

            <p>
                8. Privacidad y Protección de Datos.
                Su uso de ChatEE está sujeto a los términos de privacidad de ChatEE en el Acuerdo con su Patrono que detalla cómo recopilamos, usamos y protegemos su información personal.  Usted reconoce y acepta que la información que coloque en el Buscador entre otros datos e identificadores suyos como usuario, (en adelante “Datos Almacenados”) proporcionados o recopilados durante el uso del Software, serán retenidos por ChatEE.  Los Datos Almacenados, así como el contenido provisto por el Patrono a la Base de Datos podrán ser conservados por ChatEE durante el tiempo que estime necesario, cumpliendo con la legislación y reglamentación aplicable.  Los Datos Almacenados podrán ser utilizados por ChatEE para cualquier propósito comercial, siempre y cuando cualquier uso derivado de los mismos no identifique de forma alguna al Patrono o a usted como Empleado Participante.  
            </p> 

            <p>
                Usted expresamente renuncia a cualquier derecho a recibir o acceder en formato alguno de ChatEE una copia de los Datos Almacenados o de cualquier contenido de la Base de Datos provista por el Patrono durante o después de la vigencia de este Acuerdo.  ChatEE empleará medidas razonables para proteger los datos e identificadores personales de los Patronos y los Empleados Participantes como usted y proveerá detalles de estas según sean solicitados por el Patrono.  Sin embargo, usted acepta y entiende que ningún sistema electrónico es completamente seguro.  ChatEE, en la máxima medida permitida por ley, renuncia a cualquier responsabilidad por pérdida, robo, acceso no autorizado, divulgación, alteración, o destrucción de datos e identificadores personales como consecuencia de cualquier intrusión o brecha de seguridad en los productos y servicios provistos por terceros a ChatEE. 
            </p>

            <p>            
                9. Modificaciones a los Términos.
                ChatEE se reserva el derecho de modificar estos términos en cualquier momento.  Le notificaremos sobre cualquier cambio publicando los nuevos términos en nuestro sitio web o a través de la interfaz del software.  Su uso continuado de ChatEE después de que se hayan publicado dichos cambios constituye su aceptación de los nuevos términos.
            </p>

            <p>
                10. Terminación.
                ChatEE se reserva el derecho de terminar o suspender su acceso a ChatEE en cualquier momento, sin previo aviso ni responsabilidad, por cualquier motivo, incluido, entre otros, si usted viola estos términos.
            </p>

            <p>
                11. Ley Aplicable.
                Estos términos se regirán e interpretarán de acuerdo con las leyes del Estado Libre Asociado de Puerto Rico sin tener en cuenta sus disposiciones sobre conflictos de leyes.
            </p>

            <p>
                12. Contáctenos.
                Si tiene alguna pregunta sobre estos términos o quiere ofrecer cualquier recomendación o comentario sobre nuestros productos y servicios, por favor contáctenos a través de nuestro email support@jsrservices.org. 
            </p>

        </v-card-text>
        <v-card-actions class="justify-end">
            <v-btn 
                color="primary"
                @click="closeDialog"
            >
                Accept
            </v-btn>
        </v-card-actions>
    </v-card> 
</template>

<script>
export default {
    name: 'TermsAndConditions',
    methods: {
        closeDialog(){
            this.$emit('closeModal',true)
        }
    },
}
</script>