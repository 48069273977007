<template>
  <v-row class="pt-0 mt-0">
    <SidePage />
    <v-col cols="12" md="4" lg="4" xl="4" class="d-flex align-center">
      <v-container>
        <div class="pa-5 pa-sm-10">
          <v-row justify="center">
            <v-col cols="12" md="12" lg="12" xl="12">
              <img src="@/assets/images/logos/logo-comillas.png" width="40" />
              <h2 class="font-weight-bold mt-4 mb-4 blue-grey--text text--darken-2">Forgot Password!</h2>
              
              <v-form 
                ref="form" 
                v-model="valid" 
                lazy-validation
              >
                <v-text-field
                  class="mt-4"
                  @keydown.enter="btnClick"
                  prepend-icon="mdi-email"
                  v-model.trim="form.email"
                  hint="You will received an email"
                  name="Email"
                  :rules="emailRules"
                  label="Please enter your E-mail"
                  required
                ></v-text-field>    
                
                <v-btn
                  :disabled="!valid"
                  :loading="loading"
                  color="primary"
                  class="mr-4 my-2"
                  block
                  submit
                  @click="btnClick"
                >
                  <span>Send Email</span>
                  &nbsp;
                </v-btn>
              </v-form>

              <div class="d-block align-center mb-4 mt-3 mb-sm-0">                  
                <div class="ml-auto d-flex justify-space-between">
                  <div>
                    <small>Go back to</small>
                    <router-link :to="{ name: 'SignIn' }" class="ml-1" >Sign in</router-link>
                  </div>
                </div>
              </div>
              
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import SidePage from './SidePage.vue'
import axios from 'axios';

export default {
  name: "SignIn",
  components:{
    SidePage
  },
  data: () => ({
    valid: true,
    loader: null,
    loading: false,
    form: {
      email: "",
    },    
    show1: false,    
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ] 
  }),
  methods: {
    validate () {
      const result = this.$refs.form.validate()
      return result;
    },
    reset () {
      this.$refs.form.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    },
    async submit() {

      try {
        await axios.post('api/users/forgot-password', this.form);    
        
        this.valid = true;
        this.loader = null
        this.resetValidation()
        this.reset()
        this.$alertify.success("Email sent successfully")
      } catch ({message}) {
        this.$alertify.error(message)
        this.valid = true;
        this.loader = null
      }
    },
    btnClick(){
      this.loader = 'loading'
      const l = this.loader
      this[l] = !this[l]
      this.valid = false;

      setTimeout(() => {
        if(this.validate()){                
          this.submit()              
        }           
        (this[l] = false)
          
      }, 2000)
    },
  }
};
</script>
