import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";

import pathify from '@/plugins/vuex-pathify'

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    auth,
  //   layout
  },
  plugins: [pathify.plugin]
});


store.subscribe(mutation => {
  if(mutation.type == 'SET_MODULES') return
  store.dispatch('UPDATE', mutation)
})

store.dispatch('FETCH')

export default store