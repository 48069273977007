
import axios from 'axios'
import store from "../store";

axios.defaults.baseURL = process.env.NODE_ENV === 'production' ? 'https://www.chatee.chat/' : 'http://localhost:5000/';

// axios.defaults.baseURL = "http://localhost:5000/";
axios.defaults.withCredentials = true;

let request = 0;
let response = 0;

axios.interceptors.request.use( req => {
    request++
    store.dispatch('LOADING', true);
    const token = localStorage.getItem('token-company-ai')
    req.headers['x-auth-token'] = token;
    req.timeout = 999999999999999999999999999

    setTimeout(() => {
        if(response !== 0 && request !== 0){
            store.dispatch('LOADING', false)
            response = 0
            request = 0
        }
    }, 5000)
    
    return req;
});

axios.interceptors.response.use(res => {
    response++
    if(response === request){
        setTimeout(()=> {
            (store.dispatch('LOADING', false), response = 0, request = 0);
        }, 1500)
    } 
    return res;
},
error => {
    setTimeout(()=> {
        store.dispatch('LOADING', false)
    }, 1500)
    
    response = 0
    request = 0
    
    let message;
    
    if(error.response.status == 404){        
        message = "The URL that you're looking for was not found";
    }

    if(error.response.status == 400){
        message = error.response.data;            
    }

    if(error.response.status == 401){  
        store.dispatch('LOGOUT') 
        message = "You're not allow to perform this action";        
    } 
    
    if(error.response.status == 403){           
        store.dispatch('LOGOUT')
        message = "Invalid Session";        
    } 
    
    return Promise.reject(message);
})
   

export default axios;