<template>
  <div class="error-box blue-grey lighten-5">
    <div class="py-12">
      <div class="text-center">
        <h1 class="error-title primary--text">Your account has been verified</h1>
        <!-- <h3 class="text-uppercase error-subtitle">PAGE NOT FOUND !</h3> -->
        <p class="text-muted mt-4 mb-4 font-weight-medium text-h5">You can now sign in to your account</p>
        
        <router-link
          :to="{ name: 'SignIn' }"
          class="white--text font-weight-medium"
        >
          <v-btn color="primary">
            Go to Sign in            
          </v-btn>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "VerifyAccount",
  data: () => ({
    code: ''
  }),
  mounted() {
    this.code = this.$route.params.code;
    this.verify(this.code)
  },
  methods: {
    async verify(code){
      try {
        await axios.post('api/users/verify-account', {verification_code: code})
        
      } catch (error) {
        
        this.$alertify.error(error.message)
      }      
    }
  }
};
</script>

<style lang="scss">
.error-box {
  height: 100%;
  width: 100%;
  position: fixed;
  // background-position: top center;
  // background-repeat: no-repeat;
  // background: url("../../assets/images/background/error-bg.jpg");
}
.error-title{
  font-size: 70px;
  font-weight: 900;
  text-shadow: 1px 1px 0 #fff, 3px 3px 0 #909aa3;
  line-height: 210px;
}

@media (max-width: 991px) {
  .error-title {
    font-size: 120px;
    line-height: 120px;
  }
}

@media (max-width: 767px) {
  .error-title {
    font-size: 40px;
    line-height: 40px;
  }
}
</style>